import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { responseCls } from '../dtos/responseCls.dto';

@Injectable({
  providedIn: "root"
})
export class FileService {
  url = environment.apiUrl + '/media';

  constructor(private http: HttpClient) {
  }

  uploadFile(file_data: any): Observable<responseCls> {
    // const httpOptions = { headers: new HttpHeaders({ 'Authorization': token }) };

    //sending formdata in body for file uploader
    let formData: FormData = new FormData();
    formData.append('file', file_data);

    //return this.http.post<responseCls>(this.url + "/upload", formData, httpOptions);
    return this.http.post<responseCls>(this.url + "/upload", formData);
  }
}
