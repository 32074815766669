import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { OvertimeDto } from '../dtos/overtimes.dto';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { responseCls } from '../dtos/responseCls.dto';
import { consts } from 'src/consts/consts';

@Injectable({
  providedIn: "root"
})
export class OvertimeService {

  url = environment.apiUrl + '/overtimes';
  user = JSON.parse(localStorage.getItem('user')!);

  constructor(private http: HttpClient) { }

  getAllOvertimes(): Observable<responseCls> {
    let mParams = new HttpParams();
    mParams = mParams.append('limit', consts.pageSize.toString());
    mParams = mParams.append('skip', '0');
    const httpOptions = {
      params: mParams
    };
    if (this.user.role_name == "USER") {
      return this.http.get<responseCls>(this.url + '/me', httpOptions);
    } else {
      return this.http.get<responseCls>(this.url, httpOptions);
    }
  }

  createOvertime(overtime: OvertimeDto): Observable<responseCls> {
    if (this.user.role_name == "USER") {
      return this.http.post<responseCls>(this.url + '/me', overtime);
    } else {
      return this.http.post<responseCls>(this.url, overtime);
    }
  }

  updateOvertime(overtime: OvertimeDto): Observable<responseCls> {
    if (this.user.role_name == "USER") {
      return this.http.put<responseCls>(this.url + '/me/' + overtime.id, overtime);
    } else {
      return this.http.put<responseCls>(this.url + '/' + overtime.id, overtime);
    }
  }

  approveOvertime(overtime: OvertimeDto): Observable<responseCls> {
    return this.http.put<responseCls>(this.url + '/approve/' + overtime.id, overtime);
  }

  rejectOvertime(overtime: OvertimeDto): Observable<responseCls> {
    return this.http.put<responseCls>(this.url + '/reject/' + overtime.id, overtime);
  }

  deleteOvertime(overtimeId: number): Observable<responseCls> {
    return this.http.put<responseCls>(this.url + '/cancel/' + overtimeId, { "cancellation_note": "" });
  }

  //TODO: merge with approve overtime
  approve(id: number): Observable<never> {
    return this.http.put<never>(`${this.url}/approve/${id}`, {});
  }

  //TODO: merge with reject overtime
  reject(id: number): Observable<never> {
    return this.http.put<never>(`${this.url}/reject/${id}`, {});
  }
}
