import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { LeaveDto } from '../dtos/leaves.dto';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { responseCls } from '../dtos/responseCls.dto';
import { consts } from 'src/consts/consts';

@Injectable({
  providedIn: "root"
})
export class LeaveService {

  url = environment.apiUrl + '/leaves';
  user = JSON.parse(localStorage.getItem('user')!);

  constructor(private http: HttpClient) { }

  getAllLeaves(): Observable<responseCls> {
    let mParams = new HttpParams();
    mParams = mParams.append('limit', consts.pageSize.toString());
    mParams = mParams.append('skip', '0');
    const httpOptions = {
      params: mParams
    };
    if (this.user.role_name == "USER") {
      return this.http.get<responseCls>(this.url + "/me", httpOptions);
    } else {
      return this.http.get<responseCls>(this.url, httpOptions);
    }
  }

  createLeave(leave: LeaveDto): Observable<responseCls> {
    if (this.user.role_name == "USER") {
      return this.http.post<responseCls>(this.url + "/me", leave);
    } else {
      return this.http.post<responseCls>(this.url, leave);
    }
  }

  updateLeave(leave: LeaveDto): Observable<responseCls> {
    if (this.user.role_name == "USER") {
      return this.http.put<responseCls>(this.url + '/me/' + leave.id, leave);
    } else {
      return this.http.put<responseCls>(this.url + '/' + leave.id, leave);
    }
  }

  approveLeave(leave: LeaveDto): Observable<responseCls> {
    return this.http.put<responseCls>(this.url + '/approve/' + leave.id, leave);
  }

  rejectLeave(leave: LeaveDto): Observable<responseCls> {
    return this.http.put<responseCls>(this.url + '/reject/' + leave.id, leave);
  }

  deleteLeave(leaveId: number, cancellation_note: string): Observable<responseCls> {
    return this.http.put<responseCls>(this.url + '/cancel/' + leaveId, { "cancellation_note": cancellation_note });
  }

  //TODO: merge with approve leave
  approve(leaveId: number): Observable<never> {
    return this.http.put<never>(`${this.url}/approve/${leaveId}`, {});
  }

  //TODO: merge with reject leave
  reject(leaveId: number): Observable<never> {
    return this.http.put<never>(`${this.url}/reject/${leaveId}`, {});
  }
}
