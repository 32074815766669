import { DatePipe } from '@angular/common';

export class TableColumns {

  public static datePipee: DatePipe;
  constructor(private datePipe?: DatePipe) {
    TableColumns.datePipee = datePipe!;
  }

  public static UserColumns = {
    // id: {
    //     title: 'ID',
    //     type: 'number',
    // },
    first_name: {//first_name : name gettin from api
      title: 'First Name', // column header name
      type: 'text',
      // title: 'Full Name',
      // valuePrepareFunction: (cell: any, row: any) => { return row.first_name + " " + row.last_name }
    },
    last_name: {
      title: 'Last Name',
      type: 'text',
    },
    father_name: {
      title: 'Father Name',
      type: 'text',
    },
    email: {
      title: 'E-mail',
      type: 'text',
    },
    phone_number: {
      title: 'Phone',
      type: 'text',
    },
  };

  public static ContractColumns = {
    // id: {
    //     title: 'ID',
    //     type: 'number',
    // },
    'user': {
      title: 'Full Name',
      type: 'text',
      valuePrepareFunction: (cell: any, row: any) => {
        //cell : this column (to)
        //row : row object data
        return row.user.first_name + " " + row.user.last_name
      }
    },
    position_name: {
      title: 'Position',
      type: 'text',
    },
    type: {
      title: 'Type',
      type: 'text',
    },
    original_annual_leaves_minutes: {
      title: 'Leaves',
      type: 'text',
      valuePrepareFunction: (cell: any, row: any) => {
        //cell : this column (original_annual_leaves_minutes)
        //row : row object data
        return row.original_annual_leaves_minutes / (60 * 8.5) + " day";
      }
    },
    original_medical_leaves_minutes: {
      title: 'Medical Leaves',
      type: 'text',
      valuePrepareFunction: (cell: any, row: any) => {
        //cell : this column (original_medical_leaves_minutes)
        //row : row object data
        return row.original_medical_leaves_minutes / (60 * 8.5) + " day";
      }
    },
    to: {
      title: 'Expire at',
      type: 'date',
      valuePrepareFunction: (cell: any, row: any) => {
        //cell : this column (to)
        //row : row object data
        var raw = new Date(row.to);
        var formatted = TableColumns.datePipee!.transform(raw, 'dd/MM/yyyy');
        return formatted;
      }
    },
  };

  public static LeaveColumns = {
    // id: {
    //     title: 'ID',
    //     type: 'number',
    // },
    'user': {
      title: 'Full Name',
      type: 'string',
      valuePrepareFunction: (cell: any, row: any) => {
        //cell : this column (to)
        //row : row object data
        return row.owner.first_name + " " + row.owner.last_name
      }
    },
    from: {
      title: 'From',
      type: 'date',
      valuePrepareFunction: (cell: any, row: any) => {
        var raw = new Date(row.from);
        var formatted = TableColumns.datePipee.transform(raw, 'dd/MM/yyyy');
        return formatted;
      }
    },
    to: {
      title: 'To',
      type: 'date',
      valuePrepareFunction: (cell: any, row: any) => {
        var raw = new Date(row.to);
        var formatted = TableColumns.datePipee.transform(raw, 'dd/MM/yyyy');
        return formatted;
      }
    },
    type: {
      title: 'Type',
      type: 'string',
    },
    status: {
      title: 'Status',
      type: 'string',
    },
    note: {
      title: 'Note',
      type: 'string',
    },
  };

  public static OvertimeColumns = {
    // id: {
    //     title: 'ID',
    //     type: 'number',
    // },
    'user': {
      title: 'Full Name',
      type: 'string',
      valuePrepareFunction: (cell: any, row: any) => {
        //cell : this column (to)
        //row : row object data
        return row.owner.first_name + " " + row.owner.last_name
      }
    },
    from: {
      title: 'From',
      type: 'date',
      valuePrepareFunction: (cell: any, row: any) => {
        var raw = new Date(row.from);
        var formatted = TableColumns.datePipee.transform(raw, 'dd/MM/yyyy');
        return formatted;
      }
    },
    to: {
      title: 'To',
      type: 'date',
      valuePrepareFunction: (cell: any, row: any) => {
        var raw = new Date(row.to);
        var formatted = TableColumns.datePipee.transform(raw, 'dd/MM/yyyy');
        return formatted;
      }
    },
    status: {
      title: 'Status',
      type: 'string',
    },
    note: {
      title: 'Note',
      type: 'string',
    },
  };

  public static DepartmentColumns = {
    id: {
      title: 'ID',
      type: 'number',
      width: '14%'
    },
    manager_id: {
      title: 'Manager',
      type: 'string',
      width: '38%',
      valuePrepareFunction: (cell: any, row: any) => {
        //cell : this column (to)
        //row : row object data
        return row.manager.first_name + " " + row.manager.last_name
      }
    },
    name: {
      title: 'Dept Name',
      type: 'string',
      width: '38%'
    }
  };

  public static RoleColumns = {
    // id: {
    //     title: 'ID',
    //     type: 'number',
    //     width: '14%'
    // },
    name: {
      title: 'Role Name',
      type: 'string',
      width: '90%'
    }
  };

  public static EmploymentColumns = {
    // id: {
    //   title: 'ID',
    //   type: 'number',
    //   // width: '14%'
    // },
    'user': {
      title: 'Full Name',
      type: 'string',
      valuePrepareFunction: (cell: any, row: any) => {
        //cell : this column (to)
        //row : row object data
        return row.user.first_name + " " + row.user.last_name
      }
    },
    company_name: {
      title: 'Company Name',
      type: 'string',
      // width: '76%'
    },
    position: {
      title: 'Position',
      type: 'string',
      // width: '76%'
    },
    from: {
      title: 'From',
      type: 'date',
      valuePrepareFunction: (cell: any, row: any) => {
        var raw = new Date(row.from);
        var formatted = TableColumns.datePipee!.transform(raw, 'dd/MM/yyyy');
        return formatted;
      }
    },
    to: {
      title: 'To',
      type: 'date',
      valuePrepareFunction: (cell: any, row: any) => {
        var raw = new Date(row.to);
        var formatted = TableColumns.datePipee!.transform(raw, 'dd/MM/yyyy');
        return formatted;
      }
    },
  };

  public static CertificateColumns = {
    id: {
      title: 'ID',
      type: 'number',
      width: '14%'
    },
    'user': {
      title: 'Full Name',
      type: 'string',
      width: '38%',
      valuePrepareFunction: (cell: any, row: any) => {
        //cell : this column (to)
        //row : row object data
        return row.user.first_name + " " + row.user.last_name
      }
    },
    date: {
      title: 'Date',
      type: 'date',
      width: '38%',
      valuePrepareFunction: (cell: any, row: any) => {
        var raw = new Date(row.date);
        var formatted = TableColumns.datePipee!.transform(raw, 'dd/MM/yyyy');
        return formatted;
      }
    }
  };

  public static InterviewColumns = {
    // id: {
    //     title: 'ID',
    //     type: 'number',
    //     width: '14%'
    // },
    first_name: {
      title: 'First Name',
      type: 'string',
      // width: '90%'
    },
    last_name: {
      title: 'Last Name',
      type: 'string',
    },
    email: {
      title: 'E-mail',
      type: 'string',
    },
    phone_number: {
      title: 'Phone',
      type: 'string',
    },
    address: {
      title: 'Address',
      type: 'string',
    },
  };
}
